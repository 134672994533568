import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5sndfgi', 'template_tg0f58q', form.current, 'BRdJN8MkWP7Ct-iah')
      .then((result) => {
          console.log('SUCCESS!', result.text);
          alert('Message sent successfully!');
          form.current.reset();
      }, (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send message, please try again later.');
      });
  };

  return (
    <section id="contact" className="contact">
      <h2>Contact Us</h2>
      <form ref={form} onSubmit={sendEmail}>
        <input type="hidden" name="contact_number" value="697483" />
        <div>
          <label htmlFor="user_name">Name:</label>
          <input type="text" id="user_name" name="user_name" required />
        </div>
        <div>
          <label htmlFor="user_email">Email:</label>
          <input type="email" id="user_email" name="user_email" required />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" required />
        </div>
        <button type="submit">Send</button>
      </form>
    </section>
  );
}

export default Contact;