import React from 'react';
import banner from '../assets/milesridge banner.png';

function Hero() {
  return (
    <section id="home" className="hero">
      <img src={banner} alt="MilesRidge Banner" className="banner" />
      <h1>MILESRIDGE AI: WE BUILD PRACTICAL AI SOLUTIONS</h1>
      {/* <p>Innovative Solutions for Your Tech Needs</p> */}
    </section>
  );
}

export default Hero;