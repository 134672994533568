import React from 'react';

function About() {
  return (
    <section id="about" className="about">
      <h2>About MilesRidge AI</h2>
      <p>MilesRidge AI is a cutting-edge AI tech company specializing in innovative solutions for businesses and individuals. Our team of experts is dedicated to delivering high-quality products and services that meet the evolving needs of our clients.</p>
      
      <h3>Job Scope</h3>
      <ul>
        <li>End to end AI project development (from data collection to deployment)</li>
        <li>Data collection and segmentation (if needed)</li>
        <li>Develop and train machine learning models to meet project needs</li>
        <li>Understand business problems and translate into AI solutions</li>
        <li>Fine-tune models for performance optimization and accuracy</li>
        <li>Build data pipeline for production</li>
      </ul>
      
      <h3>General Area of Interest</h3>
      <ul>
        <li>Image Processing and Image Classification</li>
        <li>Object Detection</li>
        <li>Data Extraction</li>
        <li>LLM (AI Chatbot/Agent)</li>
        <li>Automation of manual workflow</li>
        <li>Multimodal AI Integration</li>
      </ul>
      
      <h3>Past Projects</h3>
      <ol>
        <li>Detecting AI generated content</li>
        <li>AI chatbot and agent for internal and external documents (RAG + LLM)</li>
        <li>Automatic translator tools (with LLM)</li>
        <li>Video translator</li>
        <li>Full end to end eKYC project</li>
      </ol>
    </section>
  );
}

export default About;